.header-container{
    padding: 15px 50px;
    box-shadow: 0 2px 8px #f0f1f2;
}

.header-logo{
    font-size: 25px;
    font-weight: 100;
}

.main-container{
    display: flex;
    height: calc(100% - 70px);
}

.item{
    width: 250px;
    margin: 20px 0;
    padding: 10px;
    border: 1px solid black;
    cursor: pointer;
    font-weight: 600;
    color: black;
}
.item a{
    color: black;
}

.selected{
    text-decoration: underline;
    text-decoration-color: red;
}


.item-container:hover .item{
    box-shadow: -5px 5px;
    transform: translate(5px,-5px);
    transition: all 200ms linear;
}

.page-container{
    display: flex;
    box-sizing:border-box;
    width: 100%;
    height: 100%;
    padding: 20px 0px 20px 20px;

}

.page{
    width: 100%;
    padding: 0px 40px 40px 40px;
    overflow: auto;
}